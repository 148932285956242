<template>
    <div class="w-full mx-auto flex flex-col items-center gap-6 diagonal down text-elionPurple-700">
        <div class="p-2 bg-violet-50 rounded-full">
            <h2 class="custom-h2 text-xl custom-text-gradient">{{$t('landingPage.sectionScenneUsage.title')}}</h2>
        </div>
        <div class="flex flex-col md:flex-row gap-2 px-2 text-sm md:text-xs lg:text-base overflow-hidden">
            <div
                data-aos="fade-left"
                class="relative rounded-md lg:rounded-2xl overflow-hidden"
            >
                <p :class="classBulle">{{$t('landingPage.sectionScenneUsage.pillHandFree')}}</p>
                <ImgLoading
                    :AttributeImg="{
                            source:'/images/block_scenne_usage/lunette-connectee-conduite.webp',
                            altText:$t('landingPage.sectionScenneUsage.img1Alt'),
                            titleText:$t('landingPage.sectionScenneUsage.img1title'),
                            className:'w-full md:w-11/12 lg:w-10/12 mx-auto object-cover',
                            srcset: `/images/block_scenne_usage/lunette-connectee-conduite-sm.webp 480w,
                                    /images/block_scenne_usage/lunette-connectee-conduite-md.webp 800w,
                                    /images/block_scenne_usage/lunette-connectee-conduite-lg.webp 1200w,
                                    /images/block_scenne_usage/lunette-connectee-conduite-xl.webp 2400w,
                                    /images/block_scenne_usage/lunette-connectee-conduite.webp 3600w`,

                            sizes: `(max-width: 480px) 100vw,
                                (max-width: 800px) 50vw,
                                (max-width: 1200px) 33vw,
                                (max-width: 2400px) 25vw,
                                (max-width: 3200px) 20vw,
                                16vw`,
                            width: '3600',  // Dimensions de l'image la plus grande
                            height: '800'
                    }"
                />
            </div>
            <div data-aos="fade"
                class="relative rounded-md overflow-hidden">
                <p :class="classBulle">{{$t('landingPage.sectionScenneUsage.pillTreck')}}</p>
                <ImgLoading
                    :AttributeImg="{
                            source:'/images/block_scenne_usage/lunette-connectee-rando.webp',
                            altText:$t('landingPage.sectionScenneUsage.img2Alt'),
                            titleText:$t('landingPage.sectionScenneUsage.img2title'),
                            className:'w-full lg:w-10/12 mx-auto object-cover',
                            srcset: `/images/block_scenne_usage/lunette-connectee-rando-sm.webp 480w,
                                    /images/block_scenne_usage/lunette-connectee-rando-md.webp 800w,
                                    /images/block_scenne_usage/lunette-connectee-rando-lg.webp 1200w,
                                    /images/block_scenne_usage/lunette-connectee-rando-xl.webp 2400w,
                                    /images/block_scenne_usage/lunette-connectee-rando.webp 3600w`,

                            sizes: `(max-width: 480px) 100vw,
                                (max-width: 800px) 50vw,
                                (max-width: 1200px) 33vw,
                                (max-width: 2400px) 25vw,
                                (max-width: 3200px) 20vw,
                                16vw`,
                            width: '3600',  // Dimensions de l'image la plus grande
                            height: '800'
                    }"
                />
            </div>
            <div data-aos="fade-right"
                class="relative rounded-md overflow-hidden">
                <p :class="classBulle">{{$t('landingPage.sectionScenneUsage.pillSport')}}</p>
                <ImgLoading
                    :AttributeImg="{
                            source:'/images/block_scenne_usage/lunette-connectee-sport.webp',
                            altText:$t('landingPage.sectionScenneUsage.img3Alt'),
                            titleText:$t('landingPage.sectionScenneUsage.img3title'),
                            className:'w-full lg:w-10/12 mx-auto object-cover',
                            srcset: `/images/block_scenne_usage/lunette-connectee-sport-sm.webp 480w,
                                    /images/block_scenne_usage/lunette-connectee-sport-md.webp 800w,
                                    /images/block_scenne_usage/lunette-connectee-sport-lg.webp 1200w,
                                    /images/block_scenne_usage/lunette-connectee-sport-xl.webp 2400w,
                                    /images/block_scenne_usage/lunette-connectee-sport.webp 3600w`,

                            sizes: `(max-width: 480px) 100vw,
                                (max-width: 800px) 50vw,
                                (max-width: 1200px) 33vw,
                                (max-width: 2400px) 25vw,
                                (max-width: 3200px) 20vw,
                                16vw`,
                            width: '3600',  // Dimensions de l'image la plus grande
                            height: '800'
                    }"
                />
            </div>
        </div>
        <p class="w-full md:w-8/12 mx-auto text-justify p-2 text-violet-50">
            {{$t('landingPage.sectionScenneUsage.description')}}
        </p>
    </div>
</template>
<script setup>
    import ImgLoading from "@/Components/ImgLoading.vue";
    const classImg   = 'w-auto object-cover';
    const classBulle = 'absolute bottom-2  right-2 px-2 rounded-lg lg:rounded-full bg-white border border-elionPurple-500 text-center'
</script>
